import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const Wrapper = styled.div`
  padding: 10px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  font-family: "GothamLight";

  @media (max-width: 550px) {
    flex-direction: column;
    padding: 0px;
  }
`

const VideoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "GothamLight";
  max-width: 450px;
  width: 100%;

  :first-of-type {
    margin-right: 30px;

    @media (max-width: 550px) {
      margin-right: 0px;
    }
  }

  :last-of-type {
    margin-left: 30px;

    @media (max-width: 550px) {
      margin-left: 0px;
    }
  }

  @media (max-width: 550px) {
    margin: 10px 0px;
    max-width: 100%;
  }
`

const Title = styled.h3`
  width: 100%;
  font-size: 24px;
  line-height: 1.2;
  font-family: "Gotham";
  text-transform: uppercase;
  font-weight: 500;
  color: #213d5b;
  margin-bottom: 10px;
`

const VideoImageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "GothamLight";
  border-radius: 6px;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 10px;
  cursor: pointer;

  @media (max-width: 550px) {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56%;
    margin-bottom: 10px;
  }
`

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0px;
  border-radius: 6px;

  @media (max-width: 550px) {
    display: none;
  }
`

const Iframe = styled.iframe`
  display: none;
  margin: 0;
  border: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  top: 0px;
  position: absolute;

  @media (max-width: 550px) {
    display: block;
  }
`

const VideoOverlayBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;

  @media (max-width: 550px) {
    display: none;
  }
`

const VideoPlayIcon = styled.img`
  width: 100%;
  max-width: 40px;
  object-fit: contain;
  margin: 0px;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`

const VideoDetail = styled.div`
  bottom: 10%;
  position: absolute;
  background: #213d5b;
  padding: 5px;
`

const VideoTitle = styled.div`
  font-family: "Gotham";
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
`

const VideoSubTitle = styled.div`
  font-family: "GothamLight";
  font-size: 16px;
  color: #fff;
`

const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Gotham Book";
`

const LoopVideo = ({ intl, data, section, handle, videoIndex }) => {
  return (
    <Fragment>
      <Wrapper>
        {data &&
          data.list.map((item, index) => {
            return (
              <VideoColumn key={index.toString()}>
                {section && section === "last" && (
                  <Title
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  />
                )}
                {item.image && (
                  <VideoImageBox onClick={() => handle(videoIndex + index)}>
                    <Iframe src={item.video} />
                    <Image className="lazyload" data-src={item.image} />
                    <VideoOverlayBox>
                      <VideoPlayIcon
                        className="lazyload"
                        data-src="https://static.percko.com/uploads/19d469eb-6651-40e3-ae5c-38ded39d720b.svg"
                      />
                      <VideoDetail>
                        {item.title && (
                          <VideoTitle
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}
                          />
                        )}
                        {item.subtitle && (
                          <VideoSubTitle
                            dangerouslySetInnerHTML={{
                              __html: item.subtitle,
                            }}
                          />
                        )}
                      </VideoDetail>
                    </VideoOverlayBox>
                  </VideoImageBox>
                )}
                {item.text && (
                  <VideoContent
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  />
                )}
              </VideoColumn>
            )
          })}
      </Wrapper>
    </Fragment>
  )
}

export default injectIntl(LoopVideo)
