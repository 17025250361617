import React from "react"
import styled from "@emotion/styled"

const CustomerService = ({ text }) => {
  return (
    <TextWrapper>
      <Wrapper dangerouslySetInnerHTML={{ __html: text }} />
    </TextWrapper>
  )
}

const TextWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("https://static.percko.com/uploads/520e45cb-de51-4906-86d3-1ca7bc84e62c.png");
  text-align: center;
  padding: 0px 15px;
  
  @media(max-width: 550px) {
    padding: 25px 20px;
    height: auto;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;

  > p {
    margin: 0;
  }
`

export default CustomerService
