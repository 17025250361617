import React, { useState, useEffect, useRef } from "react"
import Modal from "react-modal"
import { MdClose } from "react-icons/md"
import "flickity/css/flickity.css"
import styled from "@emotion/styled"
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null
import { isBrowser } from "../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

Modal.setAppElement(`#___gatsby`)

const customStyles = {
  overlay: {
    background: "rgba(0,0,0,0.8)",
    zIndex: 9999,
    padding: 0,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    margin: "auto",
    padding: 0,
    border: "none",
    borderRadius: 0,
    width: "100%",
    height: "100%",
  },
}

const VideoSliderPopup = ({ data, open, video, closeHandle }) => {
  var prevNext = useRef()
  const [IsOpen, setIsOpen] = useState(false)
  useEffect(() => {
    if (video) {
      $(".card").removeClass("is-selected")
      $(".card")
        .eq(video)
        .addClass("is-selected")
    }
    open ? openModal() : closeModal()
  })

  const flickityOptions = {
    freeScroll: false,
    pageDots: false,
    wrapAround: true,
    prevNextButtons: true,
    lazyLoad: true,
    initialIndex: video,
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <Modal
      isOpen={IsOpen}
      style={customStyles}
      contentLabel="Modal"
      onRequestClose={() => closeHandle(false)}
    >
      <div
        style={{
          margin: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          padding: "44px 0",
          display: "flex",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            padding: 5,
            zIndex: 9,
          }}
          onClick={() => closeHandle(false)}
        >
          <MdClose color="white" size={30} style={{ zIndex: 9 }} />
        </div>

        <Carousel>
          <div style={{ width: "100%" }}>
            <Flickity
              flickityRef={c => (prevNext = c)}
              options={flickityOptions}
              className={"carousel"}
              reloadOnUpdate
              lazyLoad={true}
            >
              {data.map((item, index) => {
                return (
                  item && (
                    <Card key={index.toString()} className="card">
                      <IframeBox>
                        <Iframe
                          src={item}
                          width="100%"
                          height="100%"
                          frameBorder="0"
                          allowFullScreen="allowfullscreen"
                        />
                      </IframeBox>
                    </Card>
                  )
                )
              })}
            </Flickity>
          </div>
        </Carousel>
      </div>
    </Modal>
  )
}

const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;

  @media (max-width: 850px) {
    padding: 0px;
  }
`

const IframeBox = styled.div`
  position: relative;
  padding-top: 40%;
  width: 100%;
`

const Iframe = styled.iframe`
  object-fit: contain;
  margin: 0;
  object-fit: fill;
  margin: 0px;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  position: absolute;
  object-position: 50% 50%;
  border: 0px;
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;

  .flickity-prev-next-button {
    background-color: transparent;
    color: #fff;
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    z-index: 3;

    @media (max-width: 600px) {
      width: 15px;
      height: 15px;
      border: 0px solid #111;
    }

    :hover {
      background-color: #111;
      color: #fff;

      @media (max-width: 600px) {
        background-color: transparent;
        color: #111;
      }
    }

    > .flickity-button-icon {
      @media (max-width: 600px) {
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
      }
    }
  }
  .flickity-prev-next-button.next {
    @media (max-width: 600px) {
      right: -15px;
    }
  }
  .flickity-prev-next-button.previous {
    @media (max-width: 600px) {
      left: -15px;
    }
  }
`

export default VideoSliderPopup
